import React from 'react';
import { Layout } from '@common';

const projectsnpublications = () => {
  return (
    <Layout page="Coming Soon">
      {/* <div style={{textAlign: 'center', margin: '120px auto'}}>
        <h3>Belum ada publikasi</h3>
      </div> */}
    </Layout>
  );
};

export default projectsnpublications;
